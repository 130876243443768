import React from "react"
import List from "../components/faq/List"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const faq = () => {
  return (
    <Layout>
      <Seo title="よくある質問" />
      <List />
    </Layout>
  )
}

export default faq
