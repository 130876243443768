import React, { useState } from "react"

type Props = {
  question: string
  answer: string
}

const Card = ({ question, answer }: Props) => {
  const [isShow, setIsShow] = useState(false)
  const onClick = () => {
    setIsShow(!isShow)
  }
  return (
    <div className="py-5 border-b border-Yellow" key={question}>
      <button className="flex items-center w-full" onClick={onClick}>
        <div className="text-2xl text-Yellow">Q</div>
        <div className="mx-4 text-left md:mx-8">{question}</div>
        <div className="ml-auto text-2xl text-Yellow">
          {isShow ? "ー" : "＋"}
        </div>
      </button>
      {isShow && (
        <div>
          <div className="mt-8 border-dash"></div>
          <div className="flex mt-8">
            <div className="text-2xl text-Yellow">A</div>
            <div className="ml-4 md:ml-8">{answer}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Card
