import React from "react"
import HeadlineM from "../HeadlineM"
import HeadlineS from "../HeadlineS"
import Card from "./Card"

const List = () => {
  const lessonFaq = [
    {
      question: "いつでも入会できるのでしょうか。",
      answer:
        "決まった入会日はありませんので、いつでも入会できます。月の途中から月額契約された場合の初月は日割りで料金を計算します。",
    },
    {
      question: "どんな人が入会できますか。",
      answer:
        "小学生からご高齢の方まで、初心者から帰国子女までどなたでもご入会いただけます。",
    },
    {
      question: "どのような教材を使うのでしょうか。",
      answer:
        "厳選した市販の教材や講師オリジナルの教材を組み合わせてListening、Speaking、Reading、Writingの4スキルをバランスよく学んでいきます。",
    },
    {
      question: "レッスンをお休みした場合、振り返りレッスンはありますか。",
      answer:
        "１週間前までにご連絡いただいたお休みの場合は、他のレッスンの空き状況に応じて振り返り可能です。直前のキャンセルは振り返りの対象外となってしまいますのでご注意ください。",
    },
    {
      question: "毎週同じ曜日、時間に通うのが難しいのですが・・",
      answer:
        "プライベートレッスンで回数券を購入いただいた場合は、ご予定に合わせて柔軟にスケジュールを組むことが可能です。",
    },
    {
      question: "グループレッスンのクラス人数は何人ですか。",
      answer: "各コース１名〜最大４名です。",
    },
    {
      question: "体験レッスンでは何をしますか。",
      answer:
        "ヒアリングをした後に、レベルに合わせたクラスで実際にレッスンを受けていただきます。生徒さんに合った学習ができるかを判断していただけます。",
    },
    {
      question: "レッスン以外にイベントなどはありますか。",
      answer:
        "毎年12月にクリスマスパーティーを開催しています。持ち寄りのご飯を食べながら、生徒さん同士の交流や情報交換、ゲームなどを楽しんでいただけています。",
    },
    {
      question: "一回のレッスン時間やレッスンの時間帯を教えてください。",
      answer:
        "各コース一回50分のレッスンです。各コースが何曜日に開催されているかは毎年生徒さんの状況によって変動しますので、お申し込み時にご相談させていただいております。",
    },
    {
      question: "宿題は出ますか。",
      answer:
        "毎日少しでも英語に触れていただくため、何か家で復習できる課題を用意する場合がありますが、必須ではありません。",
    },
    {
      question: "英検などの資格試験対策コースはありますか。",
      answer:
        "英検の面接練習、文章ライティング対策のお手伝いが可能です。個別でご相談ください。",
    },
  ]

  const studyFaq = [
    {
      question: "まったくの初心者なので、ついていけるか不安です。",
      answer:
        "経験豊富な講師が生徒様のレベルに応じて丁寧にレッスンをします。いきなり上達するのを目指すのではなく、少しずつ継続して学習していくことで楽しみながら英語がしゃべれるようになることを目指しましょう。",
    },
    {
      question: "日本語しかできないですが大丈夫でしょうか。",
      answer:
        "外国人とコミュニケーションを取ることに慣れていただくために講師はレッスン中は英語を話しますが、実は日本語もペラペラです。どうしても伝えられない時は日本で聞いてみてください。段々と英語も交えながら話せるようになれたら大丈夫です。",
    },
    {
      question: "学習上の悩みなど相談できますか。",
      answer:
        "お答えできることは全力で回答します。海外留学経験があるスタッフもいるのでお気軽にご相談ください。",
    },
  ]

  const priceFaq = [
    {
      question: "受講費以外にかかる費用はありますか。",
      answer:
        "入会金、施設使用料などは一切かかりません。コースによって教材費のみかかる場合があります。",
    },
    {
      question: "退会費用はかかりますか。",
      answer:
        "退会希望日の２ヶ月前までにご連絡いただければ退会費用はかかりません。ご連絡なしで急に退会される場合は、キャンセル料金がかかります。",
    },
    {
      question: "受講費等の支払い方法について教えてください。",
      answer:
        "クレジットカード、銀行振込、現金、PayPay、てんぐーでお支払いいただくことができます。",
    },
  ]
  return (
    <div className="mt-16">
      <HeadlineM title="よくある質問" />
      <div className="mt-16">
        <HeadlineS title="スクール・レッスンについて" />
        <div className="mt-4">
          {lessonFaq.map(item => {
            return <Card {...item} key={item.question} />
          })}
        </div>
      </div>
      <div className="mt-8">
        <HeadlineS title="英語学習について" />
        <div className="mt-4">
          {studyFaq.map(item => {
            return <Card {...item} key={item.question} />
          })}
        </div>
      </div>
      <div className="mt-8">
        <HeadlineS title="料金・お支払いについて" />
        <div className="mt-4">
          {priceFaq.map(item => {
            return <Card {...item} key={item.question} />
          })}
        </div>
      </div>
    </div>
  )
}

export default List
