import React from "react"

type Props = {
  title: string
}

const HeadlineS = ({ title }: Props) => {
  return (
    <div className="text-xl text-center font-ZenMaruGathic text-DeepGreen">
      {title}
    </div>
  )
}

export default HeadlineS
